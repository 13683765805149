.meeting.wrapper {
	background: rgb(12, 43, 64);
	height: 100%;
}

video {
	max-width: unset;
}

.meeting .ag-header {
	padding: 20px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ag-header-lead {
	font-size: 16px;
	line-height: 35px;
}

.meeting .ag-footer {
	padding: 0 30px;
	display: flex;
	align-items: center;
}

.meeting .ag-main {
	position: relative;
}

.meeting .ag-footer :first-child {
	margin-right: 80px;
}

.header-logo {
	width: 15px;
	height: 35px;
	margin-right: 12px;
}

.ag-container {
	width: calc(100% - 60px);
	height: 100%;
	margin: 0 auto;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 12px;
}

.login-content {
	font-size: 12px;
	color: #fff;
}

.logo {
	/* aspect-ratio: 0.4; */
	height: 60px;
	max-width: 25px;
}
